import {Modal, Carousel} from 'bootstrap'
import lightGallery from './galerie/lightgallery.min';
import lgVideo from './galerie/lg-video.min';
import lgAutoplay from './galerie/lg-autoplay.min';
import lgFullscreen from './galerie/lg-fullscreen.min';
import lgRotate from './galerie/lg-rotate.min';
import lgShare from './galerie/lg-share.min';
import lgThumbnail from './galerie/lg-thumbnail.min';
import lgZoom from './galerie/lg-zoom.min';

let gallery = null;
const config = {
    root: null,
    rootMargin: '0px',
    threshold: 0.9
};

function determinate(path) {
    if (!$(path).find('div.hasVideo').length || !$(path).find('video').length) {
        return `data-src="${$(path).find('input').val()}"`
    } else {
        return `data-video='{"source": [{"src":"${$(path).find('input').val()}", "type":"video/${$(path).find('input').val().slice(-3)}"}],  "attributes": {"preload": false,"loop":true, "autoplay": true, "controls": false}}'`
    }
}

function assignLazy(id) {
    let lazyImages = [].slice.call(document.querySelectorAll(`${id} img`));

    /** Then we set up a intersection observer watching over those images and whenever any of those becomes visible on the view then replace the placeholder image with actual one, remove the non-loaded class and then unobserve for that element **/
    let lazyImageObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                let lazyImage = entry.target;
                lazyImage.src = lazyImage.dataset.src;
            }
        });
    }, config);

    lazyImages.forEach(function (lazyImage) {
        lazyImageObserver.observe(lazyImage);
    });
}

function giveImages(arr) {
    $('.carousel-inner').empty();
    for (let i = 0; i < arr.length; i++) {
        $('.carousel-inner').append(`<div class="carousel-item" ${determinate(arr[i])}>
         ${($(arr[i]).find('img').length && !$(arr[i]).find('div.hasVideo').length) ? '<img class="d-block w-full" src="' + $(arr[i]).find('input').val() + '" alt="' + $(arr[i]).find('img').attr('alt') + '">' : $(arr[i]).find('div.hasVideo').length ? '<img class="d-block w-full" src="' + $(arr[i]).find('img').attr('data-src') + '" alt="' + $(arr[i]).find('img').attr('alt') + '">' : '<img class="d-block w-full" src="/images/video.png" alt="' + $(arr[i]).find('video meta').attr('content') + '">'}

</div>`)
    }
}

function assignCarousel(source) {
    $('.carousel-item').removeClass('active');
    if ($(`div[data-src="${source}"]`).length) {
        $(`div[data-src="${source}"]`).addClass('active');
    } else {
        $(`div.carousel-item`).each(function () {
            if ($(this).attr('data-video') && JSON.parse($(this).attr('data-video')).source[0].src == source) {
                $(this).addClass('active');
            }
        })
    }
}


function assignListener() {

    $('.gallery div.filter').each(function () {
        $(this).click(function (e) {
            e.stopImmediatePropagation();
            assignCarousel($(this).find('input').val())
            let galIndex = 0;
            $('.carousel-item').each(function (index) {
                if ($(this).hasClass('active')) {
                    galIndex = index;
                }
            });
            gallery = lightGallery(document.getElementById('lightgallery'), {
                selector: '.carousel-item',
                plugins: [
                    lgThumbnail,
                    lgZoom,
                    lgAutoplay,
                    lgFullscreen,
                    lgVideo
                ],
                index: galIndex,
                speed: 500,
                controls: true,
                closeOnTap: true,
                closable: true,
                autoplayVideoOnSlide: true,
                gotoNextSlideOnVideoEnd: false,
            });
            document.getElementById('lightgallery').addEventListener('lgAfterClose', () => {
                gallery.destroy();
                gallery = null;
                gallery = lightGallery(document.getElementById('lightgallery'), {
                    selector: '.carousel-item',
                    plugins: [
                        lgThumbnail,
                        lgZoom,
                        lgAutoplay,
                        lgFullscreen,
                        lgVideo,
                        // lgShare
                    ],
                    index: galIndex,
                    speed: 500,
                    controls: true,
                    closeOnTap: true,
                    closable: true,
                });
            });
            gallery.openGallery();
        });
    });
}

$(".filter-button").click(function () {
    if ($(".filter-button").removeClass("active")) {
        $(this).removeClass("active");
    }
    $(this).addClass("active");
    let value = $(this).attr('data-filter');
    if (value == "all") {
        $('#carouselFiltered').remove();
        giveImages($(`div.filter`).clone(true))
        $('#carouselExample').show('1000');
        assignLazy('#carouselExample')
    }
    else {
        $('#carouselExample').hide('fast');
        $('#carouselFiltered').remove();
        if ($(`div.${value}`).length > 0) {
            $('.categories-filter').after('<div id="carouselFiltered" class="hidden"><div class="row mb-32 mt-12 align-items-start  lg:w-4/5 w-full justify-center mx-auto gallery"></div></div>');
            let images = $(`div.${value}`).clone(true);
            giveImages(images)
            let oneMore = false;
            if ((images.length - (Math.floor(images.length / 3) * 3)) == 1) {
                oneMore = true;
            }
            let rows = Math.ceil(images.length / 3);
            for (let i = 0; i < images.length; i++) {
                if (oneMore && (i === 0 || i === rows || i === (2 * rows - 1))) {
                    $('#carouselFiltered div.gallery').append(`<div class="col-lg-4 md:p-0 p-3 col-md-12 mb-4 mb-lg-0"></div>`);
                } else if (!oneMore && (i % rows === 0)) {
                    $('#carouselFiltered div.gallery').append(`<div class="col-lg-4 md:p-0 p-3 col-md-12 mb-4 mb-lg-0"></div>`);
                }
                $('#carouselFiltered div.gallery div.col-lg-4').last().append(images[i]);
            }
            $('#carouselFiltered').show('3000');
        }
        assignLazy('#carouselFiltered');
    }
});
if ($(`div.Logo`).length > 0) {
    $('.categories-filter').after('<div id="carouselFiltered" class="hidden"><div class="row mb-32 mt-12 align-items-start  lg:w-4/5 w-full justify-center mx-auto gallery"></div></div>');
    let images = $(`div.Logo`).clone(true);
    let oneMore = false;
    if ((images.length - (Math.floor(images.length / 3) * 3)) == 1) {
        oneMore = true;
    }
    let rows = Math.ceil(images.length / 3);
    for (let i = 0; i < images.length; i++) {
        if (oneMore && (i === 0 || i === rows || i === (2 * rows - 1))) {
            $('#carouselFiltered div.gallery').append(`<div class="col-lg-4 md:p-0 p-3 col-md-12 mb-4 mb-lg-0"></div>`);
        } else if (!oneMore && (i % rows === 0)) {
            $('#carouselFiltered div.gallery').append(`<div class="col-lg-4 md:p-0 p-3 col-md-12 mb-4 mb-lg-0"></div>`);
        }
        $('#carouselFiltered div.gallery div').last().append(images[i]);
    }
    assignListener();
    giveImages(images)
    $('#carouselFiltered').show();
}
assignLazy('#carouselFiltered');
assignListener();

